<template>
    <div class="card card-top-line">
        <div class="header d-flex align-items-center justify-content-between">
            <h2>Business Logo</h2>
            <i class="fa fa-edit font-20" @click="editDetails()"></i>
        </div>
        <div class="body" v-if="!businessLogo.readonlyMode">
            <ValidationObserver v-slot="{ passes }">
                <form @submit.prevent="passes(businessLogoSubmit)" id="logo-form" ref="form">
                    <div class="d-flex logo-icon-area">
                        <div class="text-center mb-4">
                            <img :src=" businessLogo.logo" class="logo-icon" alt="logo">
                        </div>
                    </div>
                    <div class="media-body" v-if="! businessLogo.readonlyMode">
                        <ValidationProvider name="logo" rules="required" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && ! businessLogo.logo,'success': businessLogo.logo}">
                                <div class="custom-file">
                                    <input type="hidden" name="logo" v-model=" businessLogo.logo" accept="image/*">
                                    <input type="file" ref="resetInputFile" id=" businessLogo" class="form-control custom-file-input" placeholder="Upload your logo icon*" accept="image/*" @change=" businessLogoChange" />
                                    <label class="custom-file-label" for=" businessLogo">Upload your logo icon.*</label>
                                    <i class="fa fa-times" v-if="errors.length && ! businessLogo.logo"></i>
                                    <i class="fa fa-check" v-if=" businessLogo.logo"></i>
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div v-if="! businessLogo.readonlyMode" class="footer-btn mt-3 justify-content-center d-flex">
                        <b-button size="lg" @click=" businessLogo.readonlyMode = true" class="btn-mw">Cancel</b-button>
                        <b-button type="submit" variant="primary ml-1 btn-mw" size="lg">Update</b-button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')

import "@/plugins/vee-validate";

export default {
    name: 'BusinessLogo-Profile',
    components: {
        ValidationObserver,
        ValidationProvider
    },data(){
        return{
             businessLogo:{
                logo: require(`@/assets/payangel_logo.png`),
                readonlyMode : true
            },
        }
    },methods:{
        editDetails(){
            this. businessLogo.readonlyMode = false
        },

        businessLogoSubmit(){
            this. businessLogo.readonlyMode = true
        },

         businessLogoChange(e) {
            if(e.target.files.length > 0){
                let file = e.target.files[0];
                this. businessLogo.logo = URL.createObjectURL(file);
            }
        },
    }
}
</script>
